// import config from "~/config.server";

export default function (url?: string) {
  const hasWindow = typeof window !== "undefined";

  if (!url) return;

  if (hasWindow) {
    //@ts-ignore
    // console.log("config.process.env get image url", window.ENV);
    //@ts-ignore
    return window.ENV.BASEURL + url;
  } else {
    return "https://api.ru-digital.de" + url;
  }
}
