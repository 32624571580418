const copyToClipBoard = (link: string) => {
  const textarea = document.createElement("textarea");

  const id = "bookmarkt_token";
  textarea.id = id;
  // Place in top-left corner of screen regardless of scroll position.
  textarea.style.position = "fixed";
  textarea.style.top = "0";
  textarea.style.left = "0";

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textarea.style.width = "1px";
  textarea.style.height = "1px";

  // We don't need padding, reducing the size if it does flash render.
  textarea.style.padding = "0";

  // Clean up any borders.
  textarea.style.border = "none";
  textarea.style.outline = "none";
  textarea.style.boxShadow = "none";

  // Avoid flash of white box if rendered for any reason.
  textarea.style.background = "transparent";
  document.querySelector("body")?.appendChild(textarea);
  const existsTextarea = document.getElementById(id);

  if (existsTextarea) {
    // @ts-ignore
    existsTextarea.value = link;

    // @ts-ignore
    existsTextarea.select();
    try {
      const status = document.execCommand("copy");
      if (!status) {
        return false;
      }
      //   console.log('The text is now on the clipboard');
      return true;
    } catch (err) {
      // console.log('Unable to copy.');
      return false;
    }
  } else {
    //  console.log('cant find area');

    return false;
  }
};

export default copyToClipBoard;
