const getClientSideConfig = function () {
  const hasWindow = typeof window !== "undefined";
  if (hasWindow) {
    //@ts-ignore
    // console.log("config.process.env get image url", window.ENV);
    //@ts-ignore
    return window.ENV;
  }
};
export default getClientSideConfig();
