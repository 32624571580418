import { MediaRelationshipTypes } from "~/types";

export const sortFilterData = (filterData: any) => [
  {
    id: "extentIds",
    name: "Umfang",
    options: filterData[MediaRelationshipTypes.EXTENT],
  },
  {
    id: "mediaKindIds",
    name: "Medientyp",
    options: filterData[MediaRelationshipTypes.MEDIA_KIND],
  },
  {
    id: "thematicFocusIds",
    name: "Themenschwerpunkte",
    options: filterData[MediaRelationshipTypes.THEMATICFOCUS],
  },
  {
    id: "subjectIds",
    name: "Gegenstandsbereiche",
    options: filterData[MediaRelationshipTypes.SUBJECT],
  },
  {
    id: "inclusionIds",
    name: "Inklusiver Religionsunterricht",
    options: filterData[MediaRelationshipTypes.INCLUSIONS],
  },
  {
    id: "mediaCompetenceIds",
    name: "Medienkompetenz",
    options: filterData[MediaRelationshipTypes.MEDIA_COMPETENCE],
  },
  {
    id: "specialIds",
    name: "Einsatzbereich",
    options: filterData[MediaRelationshipTypes.SPECIAL],
  },
  {
    id: "educationalApplicationAreaIds",
    name: "Anwendungsbereich",
    options: filterData[MediaRelationshipTypes.ANWENDUNGSBEREICH],
  },
  {
    id: "restrictionPresenceIds",
    name: "Zugriff",
    options: filterData[MediaRelationshipTypes.RESTRICTION],
  },
  {
    id: "licenseIds",
    name: "Lizenz",
    options: filterData[MediaRelationshipTypes.LICENCE],
  },
];

export const getUniqeSearchBody = (searchParams: Array<string>) => {
  if (searchParams.length) {
    let uniqueSearchBody = [...new Set(searchParams)];
    return uniqueSearchBody;
  }
  return [];
};

export function GetSearchBody(request: any) {
  const url = new URL(request.url);
  const getSearchParams = (name: string) =>
    url.searchParams.getAll(name).filter(Boolean);

  //  console.log('url.searchParams.get("q")', getSearchParams("q"));

  const searchBody = {
    search: {
      q: getSearchParams("q").length ? getSearchParams("q")[0] : "",
      mediaKindIds: getUniqeSearchBody(getSearchParams("mediaKindIds")),
      subjectIds: getUniqeSearchBody(getSearchParams("subjectIds")),
      inclusionIds: getUniqeSearchBody(getSearchParams("inclusionIds")),
      mediaCompetenceIds: getUniqeSearchBody(
        getSearchParams("mediaCompetenceIds")
      ),
      // levelIds is removed but keep it in code incase decided to be added later
      // levelIds: getUniqeSearchBody(getSearchParams("levelIds")),
      extentIds: getUniqeSearchBody(getSearchParams("extentIds")),
      specialIds: getUniqeSearchBody(getSearchParams("specialIds")),
      licenseIds: getUniqeSearchBody(getSearchParams("licenseIds")),
      subjectAreaIds: getUniqeSearchBody(getSearchParams("subjectAreaIds")),
      educationalApplicationAreaIds: getUniqeSearchBody(
        getSearchParams("educationalApplicationAreaIds")
      ),

      tagIds: getUniqeSearchBody(getSearchParams("tagIds")),
      authorIds: getUniqeSearchBody(getSearchParams("authorIds")),
      curriculumIds: getUniqeSearchBody(getSearchParams("curriculumIds")),
      secondaryEducationIds: getUniqeSearchBody(
        getSearchParams("secondaryEducationIds")
      ),
      academicYearIds: getUniqeSearchBody(getSearchParams("academicYearIds")),
      learningUnitIds: getUniqeSearchBody(getSearchParams("learningUnitIds")),
      // restriction && thematicFocusIds wating for API
      restrictionPresenceIds: getUniqeSearchBody(
        getSearchParams("restrictionPresenceIds")
      ),
      thematicFocusIds: getUniqeSearchBody(getSearchParams("thematicFocusIds")),
    },
  };

  return searchBody;
}

export const getDefaultSelectedFilterFromParams = (
  selectID: string,
  filterData: Array<any>,
  useName: boolean,
  searchParams: any
) => {
  const params = getUniqeSearchBody(searchParams);
  // console.log(
  //   "params========params>>>>>>>>>><<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>",
  //   searchParams
  // );
  if (params.length > 0) {
    if (useName) {
      return params.map(
        (paramsSelected) =>
          filterData
            .filter(
              (selectedFilterField: any) =>
                paramsSelected == selectedFilterField.id
            )
            .map((item) => ({
              name: item.name,
              id: item.id,
            }))[0]
      );
    }
    return params.map(
      (paramsSelected) =>
        filterData.filter(
          (selectedFilterField: any) => paramsSelected == selectedFilterField.id
        )[0]
    );
  }
  return undefined;
};

export function getDefaultFormData(
  mainFilterData: any,
  weiterFilterData: any,
  tagsList: any,
  authors: any,
  url: any
) {
  // console.log(
  //   "weiterFilterDataweiterFilterDataweiterFilterData",
  //   weiterFilterData,
  //   url
  // );

  //educationalApplicationAreaIds need to check
  const getSearchParams = (name: string) => url.searchParams.getAll(name);
  let data = {
    authorIds: getDefaultSelectedFilterFromParams(
      "authorIds",
      authors,
      true,
      getSearchParams("authorIds").filter(Boolean)
    ),
    tagIds: getDefaultSelectedFilterFromParams(
      "tagIds",
      tagsList,
      true,
      getSearchParams("tagIds").filter(Boolean)
    ),

    mediaKindIds: getDefaultSelectedFilterFromParams(
      "mediaKindIds",
      weiterFilterData[MediaRelationshipTypes.MEDIA_KIND],
      false,
      getSearchParams("mediaKindIds").filter(Boolean)
    ),
    // restriction && thematicFocusIds wating for API
    restrictionPresenceIds: getDefaultSelectedFilterFromParams(
      "restrictionPresenceIds",
      weiterFilterData[MediaRelationshipTypes.RESTRICTION],
      false,
      getSearchParams("restrictionPresenceIds").filter(Boolean)
    ),
    thematicFocusIds: getDefaultSelectedFilterFromParams(
      "thematicFocusIds",
      weiterFilterData[MediaRelationshipTypes.THEMATICFOCUS],
      false,
      getSearchParams("thematicFocusIds").filter(Boolean)
    ),
    subjectIds: getDefaultSelectedFilterFromParams(
      "subjectIds",
      weiterFilterData[MediaRelationshipTypes.ANWENDUNGSBEREICH],
      false,
      getSearchParams("subjectIds").filter(Boolean)
    ),
    extentIds: getDefaultSelectedFilterFromParams(
      "extentIds",
      weiterFilterData[MediaRelationshipTypes.EXTENT],
      false,
      getSearchParams("extentIds").filter(Boolean)
    ),
    licenseIds: getDefaultSelectedFilterFromParams(
      "licenseIds",
      weiterFilterData[MediaRelationshipTypes.LICENCE],
      false,
      getSearchParams("licenseIds").filter(Boolean)
    ),
    mediaCompetenceIds: getDefaultSelectedFilterFromParams(
      "mediaCompetenceIds",
      weiterFilterData[MediaRelationshipTypes.MEDIA_COMPETENCE],
      false,
      getSearchParams("mediaCompetenceIds").filter(Boolean)
    ),
    specialIds: getDefaultSelectedFilterFromParams(
      "specialIds",
      weiterFilterData[MediaRelationshipTypes.SPECIAL],
      false,
      getSearchParams("specialIds").filter(Boolean)
    ),
    inclusionIds: getDefaultSelectedFilterFromParams(
      "inclusionIds",
      weiterFilterData[MediaRelationshipTypes.INCLUSIONS],
      false,
      getSearchParams("inclusionIds").filter(Boolean)
    ),
    secondaryEducationIds: getDefaultSelectedFilterFromParams(
      "secondaryEducationIds",
      mainFilterData["secondaryEducations"],
      true,
      getSearchParams("secondaryEducationIds").filter(Boolean)
    ),
    academicYearIds: getDefaultSelectedFilterFromParams(
      "academicYearIds",
      mainFilterData["academicYears"],
      true,
      getSearchParams("academicYearIds").filter(Boolean)
    ),
    subjectAreaIds: getDefaultSelectedFilterFromParams(
      "subjectAreaIds",
      mainFilterData["subjectAreas"],
      false,
      getSearchParams("subjectAreaIds").filter(Boolean)
    ),
    curriculumIds: getDefaultSelectedFilterFromParams(
      "curriculumIds",
      mainFilterData["curriculums"],
      true,
      getSearchParams("curriculumIds").filter(Boolean)
    ),
    q: getDefaultSelectedFilterFromParams(
      "q",
      url.searchParams.getAll("q"),
      false,
      getSearchParams("a").filter(Boolean)
    ),
  };

  return data;
}
