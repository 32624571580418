import { flatten } from "lodash";
import type { StateFieldsValue } from "~/screens/search_filter/types";

export const getURLsearchParamsFromState = ({
  fieldValue = {},
  query,
}: {
  fieldValue: StateFieldsValue;
  query: string;
  currentPage: number;
}) => {
  const urlStateObject = fieldValue;

  let urlObjecFromState = Object.keys(urlStateObject).map((key: string) =>
    urlStateObject[key]?.map((item) => {
      if (item?.value && item?.value?.id) {
        //this case for react select
        return `${key}=${item?.value?.id}`;
      }
      return `${key}=${item?.id}`;
    })
  );

  if (query) {
    urlObjecFromState.push([`q=${query}`]);
  }
  //   console.log(
  //     "🚀 ~ file: get_params_from_state.ts:13 ~ urlStateObject:",
  //     //  Object.keys(urlStateObject),
  //     fieldValue,
  //     flatten(urlObjecFromState)
  //   );

  return {
    url: flatten(urlObjecFromState).join("&"),
  };
};
