import { useMediaQuery } from "react-responsive";

// /** @type {import('tailwindcss').Config} */
//  {
//   theme: {
//     screens: {
//       'sm': '640px',
//       // => @media (min-width: 640px) { ... }

//       'md': '768px',
//       // => @media (min-width: 768px) { ... }

//       'lg': '1024px',
//       // => @media (min-width: 1024px) { ... }

//       'xl': '1280px',
//       // => @media (min-width: 1280px) { ... }

//       '2xl': '1536px',
//       // => @media (min-width: 1536px) { ... }
//     }
//   }

// /* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {...}

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {...}

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {...}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}

export default function GetScreenSize() {
  const xxl = useMediaQuery({ query: "(min-width: 1536px)" });

  const xl = useMediaQuery({ query: "(min-width: 1280px)" });

  const ls = useMediaQuery({ query: "(min-width: 1024px)" });
  // const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 640px)" });

  if (xl) return "xlarge";
  if (ls) return "large";
  if (md) return "medium";
  if (sm) return "small";
  if (xxl) return "xxl";
  return "small";
}
