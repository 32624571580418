import { useEffect, useState } from "react";
import { uniqBy } from "lodash";

import type { IApiDataItemBase, IMedia, IMediaRelationships } from "~/types";
// import GetScreenSize from "./get_screen_size";

const reorderData = (
  dataScope: Array<IApiDataItemBase<IMedia, IMediaRelationships>>,
  memoizedSize: "small" | "xlarge" | "large" | "medium" | "xxl"
) => {
  if (!dataScope) return [];
  //@ts-ignore

  // console.log("memoizedSize======", memoizedSize);
  let columnsNumber;
  // const lastArrayLength = useRef(arr.length);
  if (memoizedSize === "xlarge" || memoizedSize === "xxl") {
    columnsNumber = 4;
  } else if (memoizedSize === "large") {
    columnsNumber = 3;
  } else if (memoizedSize === "medium") {
    columnsNumber = 2;
  } else {
    columnsNumber = 1;
  }
  // console.log("columnsNumber================", columnsNumber);
  const cols = columnsNumber;
  const sortedCards = [];
  let col = 0;
  while (col < cols) {
    for (let i = 0; i < dataScope.length; i += cols) {
      let _val = dataScope[i + col];

      if (_val !== undefined) sortedCards.push(_val);
    }
    col++;
  }
  // if (lastArrayLength.current !== arr.length) {
  //   // console.log("run recursion", lastArrayLength.current, currentPage);
  //   // Reorder(arr, memoizedSize, lastArrayLength.current ? lastArrayLength.current : 1);
  // }
  return sortedCards;
};
// import { useRef } from "react";
const Reorder = ({
  stateData,
  serverData,
  mainFilterCount,
  weiterFilterCount,
  hasMore,
  transitionState,
  size,
  query,
}: {
  stateData: any;
  serverData: any;
  mainFilterCount: number;
  weiterFilterCount: number;
  hasMore: boolean;
  transitionState: string;
  size: "small" | "xlarge" | "large" | "medium" | "xxl";
  query: string;
}) => {
  const [data, setData] =
    useState<Array<IApiDataItemBase<IMedia, IMediaRelationships>>>();
  // console.log("data rende in side reorder functionr", data);
  // const memoizedSize = useMemo(() => size, [size]);
  // console.log("🚀 ~ file: memoizedSize:", memoizedSize);
  useEffect(() => {
    if (query || weiterFilterCount > 0 || mainFilterCount > 0) {
      // console.log(
      //   "🚀query || weiterFilterCount > 0 || mainFilterCount > 0 & condition= serverData.length < 39 && !hasMore",
      //   serverData.length < 39 && !hasMore,
      //   serverData
      // );
      // make sure to get results from server if threr is no next page
      let finalData = serverData?.length < 39 ? serverData : stateData;

      setData(finalData);
    } else if (transitionState === "loading" && query === "") {
      // console.log('transitionState === "loading" && query === ""', serverData);
      setData(serverData);
    } else {
      // console.log("else data", stateData);
      // console.log("data uniqby", stateData);
      setData(stateData);
    }
  }, [
    hasMore,
    mainFilterCount,
    query,
    serverData,
    // memoizedSize,
    stateData,
    transitionState,
    weiterFilterCount,
    // size,
  ]);
  // console.log("data uniqby", uniqBy(data, "id"));
  // return [reorderData(uniqBy(data, "id"), size)];
  return [uniqBy(data, "id")];
};
export default Reorder;
