// export const dateFormat = (dateString: string) =>
//   new Intl.DateTimeFormat("de-DE").format(new Date(dateString));

export const dateFormat = ({
  date,
  option,
}: {
  date: string;
  option?: {
    hour?: boolean;
    minute?: boolean;
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
  };
}) => {
  const rtf = new Intl.DateTimeFormat("de", {
    day: "numeric",
    month: option && option.month ? option.month : "numeric",
    year: "numeric",
    hour: option && option.hour ? "2-digit" : undefined,
    minute: option && option.minute ? "2-digit" : undefined,
  });

  return rtf.format(new Date(date));
};
